import { Link, graphql, useStaticQuery } from "gatsby";
import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Img from "gatsby-image";
import { getFluid } from "../../util/helper";

const fmTitle = "Vannak Magyarországon csatatér-körzetek?";
const fmSlug = "csatater-korzetek";
const fmDate = "2022. március 8.";

export const CsataterKorzetekAbstract = () => (
	<section>
		<Link to={`/blog/${fmSlug}`}>
			<h1 className="h1 cursor-pointer">{fmTitle}</h1>
		</Link>
		<p className="text-gray-500 mt-2">{fmDate}</p>
		<div className="mt-4">
			Az Amerikai Egyesült Államokban az elnökválasztások kapcsán megszokott dolog csatatér-államokról beszélni.
			De vajon mennyire lehet ezt a közelgő magyarországi választásokra alkalmazni?
		</div>
		<div className="mt-4">
			A tengerentúlon a verseny néhány szoros államban dől el: az ottani választási rendszer szerint az adott
			államot nyerő jelölt az állam összes elektorát megkapja, és végül a választást a több elektorral rendelkező
			jelölt nyeri. A kampány nagy részben ezekre a csatatér-államokra koncentrál, és összességében kicsi országos
			szavazati különbség az elektorok között nagy különbséget jelenthet attól függően, hogy mi ezeken a
			kulcsfontosságú helyeken az eredmény.
		</div>
		<div className="mt-4">
			Mivel az áprilisi magyarországi választásokon idén 2006 után először ismét kétpólusú verseny lesz, és mivel
			a magyar választási rendszerben szintén az{" "}
			<Link className="link" to={`/blog/egyeni-korzetekben-fog-eldolni-a-2022-es-valasztas`}>
				egyéni körzetekben lejátszott párharcok
			</Link>{" "}
			határozzák meg a végeredményt, az itthoni közbeszédben is gyakran hallani csatatér-körzetekről.
		</div>
		<div className="mt-4">
			<div className="flex justify-between">
				<div></div>
				<div>
					<Link className="link" to={`/blog/${fmSlug}`}>
						tovább ⟶
					</Link>
				</div>
			</div>
		</div>
	</section>
);

interface ArticleProps {
	fluids: { [key: string]: any };
}

const FullArticle = (props: ArticleProps) => (
	<section>
		<h1 className="h1">{fmTitle}</h1>
		<p className="text-gray-500 mt-2">{fmDate}</p>
		<div className="mt-4">
			Az Amerikai Egyesült Államokban az elnökválasztások kapcsán megszokott dolog csatatér-államokról beszélni.
			De vajon mennyire lehet ezt a közelgő magyarországi választásokra alkalmazni?
		</div>
		<div className="mt-4">
			A tengerentúlon a verseny néhány szoros államban dől el: az ottani választási rendszer szerint az adott
			államot nyerő jelölt az állam összes elektorát megkapja, és végül a választást a több elektorral rendelkező
			jelölt nyeri. A kampány nagy részben ezekre a csatatér-államokra koncentrál, és összességében kicsi országos
			szavazati különbség az elektorok között nagy különbséget jelenthet attól függően, hogy mi ezeken a
			kulcsfontosságú helyeken az eredmény.
		</div>
		<div className="mt-4">
			Mivel az áprilisi magyarországi választásokon idén 2006 után először ismét kétpólusú verseny lesz, és mivel
			a magyar választási rendszerben szintén az{" "}
			<Link className="link" to={`/blog/egyeni-korzetekben-fog-eldolni-a-2022-es-valasztas`}>
				egyéni körzetekben lejátszott párharcok
			</Link>{" "}
			határozzák meg a végeredményt, az itthoni közbeszédben is gyakran hallani csatatér-körzetekről.
		</div>
		<div className="mt-4">
			<span>Összehasonlítottuk a két ország választási eredményeit, és a következőket találtuk:</span>
			<ul className="mt-2 ul">
				<li>
					Az Egyesült Államokban két választás között alig változik egy-egy államon belül a két párt közötti
					eredmény. Ennek következtében több az átfedés két választás csatatér-államai között, így ezek az
					államok akár egy évtizeden is át állandó csatatérként jelennek meg a politikában.
				</li>
				<li>
					Magyarországon alapvetően megváltozik két választás között a színtér. Minden választásnak megvoltak
					a saját szoros küzdelmei. Az előrejelzést nehezíti, hogy itthon nagyon kevés adat áll
					rendelkezésünkre az egyes körzetekről: nincsenek nyilvános közvéleménykutatások, míg Amerikában
					rengeteg állami szintű felmérés van, illetve több időközi választás is van állami szinten, mind
					szövetségi (képviselőházi, szenátusi) és állami (kormányzó, állami törvényhozás, ügyész) pozíciókra.
				</li>
			</ul>
		</div>
		<div className="mt-4">
			A kérdés megvizsgálásához fogtuk az elmúlt választások eredményeit mindkét országban, és megnéztük, hogy
			mekkora változások voltak államonként illetve egyéni körzetenként. Magyarországon a hatpárti ellenzéki
			pártok eredményét összeadtuk, hiszen idén együtt fognak indulni a pártok. Azokat a versenyeket definiáltuk
			csatatérként illetve szoros körzetként, ahol 10%-on belüli volt az eredmény. Illetve megnéztük azt is, hogy
			a két választás között mennyiben változtak a csatatér-körzetek.
		</div>
		<div className="mt-4">
			A következő ábrákon a két legutóbbi választás eredményeit hasonlítottuk össze. Az ábrán az átló jelenti a 0
			változást, és mivel az amerikai ábrán a legtöbb állam eredménye csak csekély mértékben tér le az átlóról,
			így látszik, hogy az államok többségében csak kisebb mértékben változtak az eredmények. Pár kivétellel az
			összes 2016-os csatatér állam 2020-ban is csatatér maradt. A kis változás ellenére - a fent leírt választási
			logika miatt - jelentős, 26%-os különbség volt az elektorok számában. Így lehetett az is, hogy a 2016-os
			választásokat a republikánus Donald Trump nyerte, míg a 2020-ast a demokrata Joe Biden.
		</div>
		<p className="md:mx-32 mt-4">
			<iframe
				title="Csatatér-államok az Egyesült Államokban 2016-2020"
				aria-label="chart"
				id="datawrapper-chart-A8Veb"
				src="https://datawrapper.dwcdn.net/A8Veb"
				scrolling="no"
				frameBorder="0"
				style={{ width: "100%", border: "none", minWidth: "100% !important", height: "590px" }}
			></iframe>
		</p>
		<div className="mt-4">
			A magyar ábrán látszik, hogy jóval nagyobb változások voltak OEVK szinten az eredményekben. Mivel 2014-ben a
			Fidesz 2018-hoz (és 2010-hez) képest jelentősen rosszabbul szerepelt az összes szavazatot tekintve, azt
			látjuk, hogy a legtöbb körzet az átló alatt szerepel, átlagban 7%-kal. Ráadásul az egyes körzetek között
			jóval nagyobb különbségeket tapasztalhattunk. A legnagyobb elmozdulások a Fidesz felé a Szabolcs 6-os
			illetve a Heves 3-as körzetekben voltak, ahol nagyjából 20%-ot javult a kormánypárt eredménye, 13%-kal
			haladva meg az országos átlagot. Az ellenzék irányába legtöbbet a BP 3-as körzet mozdult, ahol 8%ot javult
			az ellenzék eredménye, 15%-kal felülteljesítve az országos átlagot.
		</div>
		<p className="md:mx-32 mt-4">
			<iframe
				title="OEVK eredmények 2014-2018"
				aria-label="chart"
				id="datawrapper-chart-qVZpQ"
				src="https://datawrapper.dwcdn.net/qVZpQ"
				scrolling="no"
				frameBorder="0"
				style={{ width: "100%", border: "none", minWidth: "100% !important", height: "590px" }}
			></iframe>
		</p>
		<div className="mt-4">
			Ha két választási ciklus, azaz 8 év alatt bekövetkezett választásokat nézzük, akkor az amerikai ábra már
			jobban hasonlít a magyarra abban a tekintetben, hogy nagyobb az állami szintű változások közötti szórás.
			Maguk a csataterek amerikában kevesebbet változtak még így is.
		</div>
		<p className="md:mx-32 mt-4">
			<iframe
				title="Csatatér-államok az Egyesült Államokban 2012-2020"
				aria-label="chart"
				id="datawrapper-chart-37AZX"
				src="https://datawrapper.dwcdn.net/37AZX"
				scrolling="no"
				frameBorder="0"
				style={{ width: "100%", border: "none", minWidth: "100% !important", height: "590px" }}
			></iframe>
		</p>
		<p className="md:mx-32 mt-4">
			<iframe
				title="OEVK eredmények 2010-2018"
				aria-label="chart"
				id="datawrapper-chart-ahgTp"
				src="https://datawrapper.dwcdn.net/ahgTp"
				scrolling="no"
				frameBorder="0"
				style={{ width: "100%", border: "none", minWidth: "100% !important", height: "590px" }}
			></iframe>
		</p>
		<div className="mt-4">
			A következő táblázatokban összefoglaltuk országonként a vizsgált három választás eredményeit, a szoros
			küzdelmekre koncentrálva.
		</div>
		<p className="md:mx-32 mt-4">
			<iframe
				title="Amerikai elnökválasztások 2012-2020"
				aria-label="chart"
				id="datawrapper-chart-55uLI"
				src="https://datawrapper.dwcdn.net/55uLI"
				scrolling="no"
				frameBorder="0"
				style={{ width: "100%", border: "none", minWidth: "100% !important", height: "480px" }}
			></iframe>
		</p>
		<p className="md:mx-32 mt-4">
			<iframe
				title="Magyarországi parlamenti választások 2012-2020"
				aria-label="chart"
				id="datawrapper-chart-6YJ8B"
				src="https://datawrapper.dwcdn.net/6YJ8B"
				scrolling="no"
				frameBorder="0"
				style={{ width: "100%", border: "none", minWidth: "100% !important", height: "400px" }}
			></iframe>
		</p>
		<div className="mt-4">
			A két országban nagyságrendileg egyenlő mértékű körüli a szoros, 10%-on belüli küzdelmek aránya, körülbelül
			harmada-fele az összes államnak illetve körzetnek. Amerikában két választás között a csatatér-államok szinte
			megegyeznek: a 10%-nál szorosabb körzetek több mint 80%-ban megegyeznek bármely két, 2012 utáni választást
			nézve. Magyarországon a 2010-es szoros körzeteknek csak a 20%-a volt szoros 2014-ben. A legnagyobb átfedés
			2014 és 2018 szoros körzetei között volt, de ez is csak 70%-a a 2014-es szoros körzetek számának.
		</div>
		<div className="mt-4">
			Összességében megállapítható, hogy Magyarországon nincsek az Egyesült Államokhoz hasonló csatatérkörzetek,
			amelyek több választáson keresztül is meghatároznák a választás kimenetelét. Ráadásul, az Egyesült
			Államokban a nem csatatér államoknak gyakorlatilag nincsen relevanciájuk a végső kimenettel kapcsolatban: az
			a választás szempontjából irreleváns, hogy a demokraták 20 vagy 25%-kal nyerik meg New York államot, vagy a
			republikánusok mennyivel nyerik meg Alaszkát. Magyarországon a listás- és a kompenzációs szavazatok miatt a
			nem szoros körzetekben is fontos a végeredmény, bár kisebb mértékben van hatásuk a végeredményre.
		</div>
		<div className="mt-4">
			Az április választásokkal kapcsolatban egyelőre még nem lehet tudni pontosan, hogy melyek lesznek a szoros
			körzetek. A közvélemény kutatások átlaga szerint a Fidesz vezet, de egyrészt a közvélemény kutatások sem
			pontosak (még Amerikában is{" "}
			<a
				className="link"
				href="https://fivethirtyeight.com/features/why-was-the-national-polling-environment-so-off-in-2020/"
			>
				tévednek
			</a>{" "}
			az átlagolt eredmények is), másrészt az ukrajnai háború miatt rengeteg a bizonytalanság, és még sok minden
			történhet a hátralévő időben.
		</div>
		<div className="mt-4">
			Ugyanakkor, mindkét oldalnak kulcsfontosságú a szoros körzetek beazonosítása. Ugyanis ezekben a körzetekben
			kevés szavazattal nagy változást lehet elérni. Általában mindkét oldalon van 5-10 körzet, ahol 2000
			szavazaton belül van az eredmény. Ezekben adott esetben 10-15 ezer jól irányzott szavazatnak nagy hatása
			lehet: mondjuk egy döntetlen közeli parlamenti eredményből, biztos 15 fős mandátumtöbbséget tud teremteni
			még a kompenzációs hatás figyelembevétele után is. Ezzel szemben listán 80 ezer szavazat kell egy
			mandátumhoz: egy hasonló mandatátumbeli változáshoz 600 ezer szavazót kell átcsábítani a másik oldalról,
			vagy 1.2 millió külhoni magyart kellene "beszervezni" levélszavazónak.
		</div>
		<div className="mt-4">
			Ugyan azt nem lehet tudni, hogy melyek lesznek a szoros körzetek, ugyanakkor azt már jobban lehet sejteni,
			hogy egy szoros választás esetén, amikor az ellenzék és a Fidesz nagyjából egyenlően szerepelnek országosan,
			melyek lesznek a szoros körzetek. Ráadásul, a 2022-es választásokon mindenképp lesz néhány körzet, amikben
			szimbolikus csatákat vív a Fidesz és az ellenzék jelöltje, és amikre még évek múlva is emlékezni fogunk. A
			legnagyobb párharc a hódmezővásárhelyi székhelyű Csongrád 4-es körzetben lesz, ahol az ellenzék
			miniszterelnök jelöltje Márki-Zay Péter a korábbi kancelláriaminiszter Lázár Jánossal csap össze.
		</div>
	</section>
);

export default () => {
	// const data = useStaticQuery(graphql`
	// 	query ServicesPageQuery {
	// 		allFile(filter: { relativePath: { in: ["vegyes-rendszer.png", "szavazat-mandatum.png"] } }) {
	// 			edges {
	// 				node {
	// 					relativePath
	// 					childImageSharp {
	// 						fluid(maxWidth: 1024) {
	// 							...GatsbyImageSharpFluid
	// 							...GatsbyImageSharpFluidLimitPresentationSize
	// 						}
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// `);

	const fluids = {
		// vegyesRendszer: getFluid(data.allFile.edges, "vegyes-rendszer.png"),
		// szavazatMandatum: getFluid(data.allFile.edges, "szavazat-mandatum.png"),
	};

	return (
		<Layout>
			<SEO title={fmTitle} />
			<div className="container px-4 py-8">
				<FullArticle fluids={fluids} />
			</div>
		</Layout>
	);
};
